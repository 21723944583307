<template>
  <div class="bg-white min-h-screen flex flex-column" ref="el">

    <Navbar
        title="Institutes"
        :nav-entries="navEntries"
        :user-entries="userEntries"
        :username="user?.name" />

    <div class="mt-7 mb-4 mx-5 flex flex-column flex-auto">
      <div class="content">
        <slot />
      </div>
    </div>

    <Flashes />
    <ConfirmDialog />

  </div>

</template>

<script setup>

import {computed} from 'vue'
import { usePage } from '@inertiajs/vue3'
import Navbar from './Partials/Navbar.vue'
import Flashes from '@/Partials/Flashes.vue'
import ConfirmDialog from 'primevue/confirmdialog'

const user = computed(() => usePage().props.auth.user)

const navEntries = computed(() => {
  if (usePage().props.auth.user !== null) {

    return [
      {label: 'Invitations', url: '/invitations', key: 'invitation'},
      {label: 'Participants', url: '/participants', key: 'participants'},
      {label: 'Exports', url: '/exports', key: 'exports'},
      {label: 'Settings', url: '/settings', key: 'settings'},
    ]
  }
})

const userEntries = computed(() => {

  const user = usePage().props.auth.user;

  if (user !== null) {
    return [
        {label: 'Logout', url: '/logout'},
    ]

  }
})


</script>
