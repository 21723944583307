<template>
  <div class="bg-white min-h-screen flex flex-column" ref="el">

    <Navbar
        title="Admin"
        :nav-entries="navEntries"
        :user-entries="userEntries"
        :username="user?.email" />

    <div class="mt-7 mb-4 mx-5 flex flex-column flex-auto">
      <div class="content">
        <slot />
      </div>
    </div>

    <Flashes />
    <ConfirmDialog />

  </div>

</template>

<script setup>

import {computed} from 'vue'
import { usePage } from '@inertiajs/vue3'
import Navbar from './Partials/Navbar.vue'
import Flashes from '@/Partials/Flashes.vue'
import ConfirmDialog from 'primevue/confirmdialog'

const user = computed(() => usePage().props.auth.user)

const navEntries = computed(() => {
  if (usePage().props.auth.user !== null) {

    return [
      {label: 'Questions', url: '/questions', key: 'question'},
      {label: 'Categories', url: '/categories', key: 'category'},
      {label: 'Institutions', url: '/institutions', key: 'institution'},
      {label: 'Import', url: '/import', key: 'import'},
      {label: 'Export', url: '/export', key: 'export'},
    ]
  }
})

const userEntries = computed(() => {

  const user = usePage().props.auth.user;

  if (user !== null) {

    let entries = [
      {label: 'Profile', url: '/user'},
    ]

    if (user.role === 'administrator') {
      entries.push({label: 'Administration', url: '/administration'})
    }

    entries.push({label: 'Logout', url: '/logout'})

    return entries
  }
})


</script>

<style lang="scss">
</style>
