export function scrollToId(id, offset) {

  const element = document.getElementById(id);

  if (element) {
    const headerOffset = offset ?? 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })

    element.focus()
  }
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;

    while (k--) {
      arr.push(undefined)
    }

  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])

}

export function urlParam (name, defaultValue) {
  return new URLSearchParams(window.location.search).get(name) ?? (defaultValue ?? null)
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function uc(str) {
  return str.toUpperCase()
}


