<template>

  <div class="bg-white py-0 px-5 flex align-items-center justify-content-between fixed border-bottom-1 border-300 shadow-1" style="top: 0; right: 0; left: 0; z-index: 1000">
    <div class="content flex align-items-center">
      <img src="/img/icon.png" alt="logo" height="40" class="mr-0 " :class="username ? 'lg:mr-3' : 'lg:mr-2'">

      <div class="text-secondary" v-if="! username" style="white-space: nowrap">Pebbles App {{ title }}</div>
      <a v-if="userEntries"
         class="cursor-pointer block lg:hidden text-600 ml-auto"
         v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
        <i class="pi pi-bars text-4xl text-600 pt-1"></i>
      </a>

      <div class="align-content-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-white left-0 top-100 z-1 shadow-2 lg:shadow-none lg:align-items-center"
           :class="{ 'hidden': showMenu}"
           style="height: 3rem">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li v-for="entry in navEntries" class="p-0 m-0 flex align-items-center">
            <Link :href="entry.url"
                  @click="showMenu = !showMenu"
                  class="flex text-sm w-full no-underline px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:bg-gray-100 cursor-pointer transition-colors transition-duration-150"
                  style="height: 3rem"
                  :class="isCurrent(entry) ? 'bg-gray-100': 'bg-white'"
                  v-text="entry.label">
            </Link>
          </li>
        </ul>

        <ul v-if="username"
            class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-bottom-1 lg:border-top-none lg:border-bottom-none border-gray-300 bg-white">
          <li class="lg:relative">
            <a class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-round cursor-pointer transition-colors transition-duration-150"
               v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
              <span v-text="username" class="text-sm"/>
              <i class="pi pi-angle-down ml-auto lg:ml-2"></i>
            </a>
            <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer"
                :class="{ 'hidden': showMenu}">
              <li v-for="entry in userEntries">
                <Link :href="entry.url"
                      @click="showMenu = !showMenu"
                      v-text="entry.label"
                      class="flex text-sm no-underline p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150">

                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>

import { usePage } from '@inertiajs/vue3'

defineProps({
  title: String,
  navEntries: Array,
  username: String,
  userEntries: Array,
})

function isCurrent(entry) {
  return usePage().url.startsWith(`/${entry.key}`) || usePage().url.startsWith(`${entry.url}`)
}

let showMenu = $ref(false)

</script>
